import feedback from '@/modules/strubs/feedback'
import api from '@/modules/strubs/api'
import offer from '@/modules/strubs/offer'

const settings = {
  delay: 0,
  callback_url: '',
  clickers_only: false,
  openers_only: false,
  feedback: feedback,
  api: api,
  offer: offer
}

export default settings
