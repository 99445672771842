import contactList from '@/modules/strubs/contactList'
import template from '@/modules/strubs/template'
import domain from '@/modules/strubs/domain'
import senderCategory from '@/modules/strubs/senderCategory'
import campaign from '@/modules/strubs/campaign'
import feedbackSettings from '@/modules/strubs/feedbackSettings'

export default {
  id: 0,
  name: '',
  active: false,
  event: 0,
  event_text: '',
  action: 0,
  action_text: '',
  created_at: '',
  updated_at: '',
  settings: feedbackSettings,
  lists: [contactList],
  campaigns: [campaign],
  templates: [template],
  domains: [domain],
  sender_categories: [senderCategory],
  metadata: {
    editable: {
      this: false
    }
  }
}
