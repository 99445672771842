<template>
  <div>
    <portal to="page-top-title">Feedback #{{ id }}</portal>
    <portal v-if="feedback.metadata.editable.this"  to="page-top-right">
      <btn :route="{ name: 'feedbacks-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
    </portal>

    <box-single :url="`/feedbacks/${id}`" loader data-prop="feedback" resp-prop="data" @request-after="requestAfter">
      <template slot="content">
        <row>
          <column-info title="Name">{{ feedback.name }}</column-info>
          <column-info title="Active"><badge-active :value="feedback.active"/></column-info>
          <column-info title="Created At">{{ feedback.created_at }}</column-info>
          <column-info title="Updated At">{{ feedback.updated_at }}</column-info>
        </row>
        <row>
          <column-info title="Event">{{ feedback.event_text }}</column-info>
          <column-info title="Action">{{ feedback.action_text }}</column-info>
        </row>
        <template v-if="feedback.event === events.CLICK">
          <row>
            <column-info :title="`Campaigns (${feedback.campaigns.length})`">
              <em class="text-info" v-if="!feedback.campaigns.length">None</em>
              <div class="td-rows comma" v-else>
                <div class="td-row" v-for="campaign in feedback.campaigns" :key="`feedback-${campaign.id}`">
                  <router-link :to="{ name: 'campaigns-single', params: { id: campaign.id } }">{{ campaign.name }}</router-link>
                </div>
              </div>
            </column-info>
          </row>
        </template>
        <template v-if="feedback.event === events.REGISTRATION">
          <row>
            <column-info :title="`Lists (${feedback.lists.length})`">
              <em class="text-info" v-if="!feedback.lists.length">None</em>
              <div class="td-rows comma" v-else>
                <div class="td-row" v-for="list in feedback.lists" :key="`feedback-${list.id}`">
                  <router-link :to="{ name: 'contactLists-single', params: { id: list.id } }">{{ list.name }}</router-link>
                </div>
              </div>
            </column-info>
            <column-info title="Openers Only">
              <badge-active :value="feedback.settings.openers_only"/>
            </column-info>
            <column-info title="Clickers Only">
              <badge-active :value="feedback.settings.clickers_only"/>
            </column-info>
          </row>
        </template>
        <template v-if="feedback.action === actions.CALLBACK">
          <row>
            <column-info :md="12" :lg="12" title="Callback URL">{{ feedback.settings.callback_url }}</column-info>
          </row>
        </template>
        <template v-if="feedback.action === actions.EMAIL">
          <row>
            <column-info title="Api">
              <nullable :value="feedback.settings.api">
                <template slot="value">
                  <router-link v-if="feedback.settings.api" :to="{ name: 'apis-single', params: { id: feedback.settings.api.id } }">{{ feedback.settings.api.name }}</router-link>
                </template>
              </nullable>
            </column-info>
            <column-info title="Offer">
              <nullable :value="feedback.settings.offer">
                <template slot="value">
                  <router-link v-if="feedback.settings.offer" :to="{ name: 'offers-single', params: { id: feedback.settings.offer.id } }">{{ feedback.settings.offer.name }}</router-link>
                </template>
              </nullable>
            </column-info>
            <column-info title="Delay">
              <nullable :value="feedback.settings.delay"/>
            </column-info>
          </row>
          <row>
            <column-info :title="`Domains (${feedback.domains.length})`">
              <em class="text-info" v-if="!feedback.domains.length">None</em>
              <div class="td-rows comma" v-else>
                <div class="td-row" v-for="domain in feedback.domains" :key="`feedback-${domain.id}`">
                  <router-link :to="{ name: 'domains-single', params: { id: domain.id } }">{{ domain.name }}</router-link>
                </div>
              </div>
            </column-info>
            <column-info :title="`Templates (${feedback.templates.length})`">
              <em class="text-info" v-if="!feedback.templates.length">None</em>
              <div class="td-rows comma" v-else>
                <div class="td-row" v-for="template in feedback.templates" :key="`feedback-${template.id}`">
                  <router-link :to="{ name: 'templates-single', params: { id: template.id } }">{{ template.name }}</router-link>
                </div>
              </div>
            </column-info>
          </row>
        </template>
        <row>
          <column-info title="Messages">
            <stats-messages :stats="feedbackStats"/>
          </column-info>
          <column-info title="Clicks">
            <stats-clicks :stats="feedbackStats"/>
          </column-info>
          <column-info title="Revenues">
            <stats-revenues :stats="feedbackStats"/>
          </column-info>
        </row>
      </template>
    </box-single>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import Btn from '@/views/components/simple/Btn'
import Nullable from '@/views/components/simple/Nullable'
import BadgeActive from '@/views/components/simple/BadgeActive'
import StatsMessages from '@/views/components/stats/Messages'
import StatsClicks from '@/views/components/stats/Clicks'
import StatsRevenues from '@/views/components/stats/Revenues'
import FeedbackEvents from '@/configs/feedback-events'
import FeedbackActions from '@/configs/feedback-actions'
import feedback from '@/modules/strubs/feedback'

export default {
  name: 'FeedbacksSingle',
  metaInfo () {
    return { title: 'Feedback #' + this.id }
  },
  components: {
    BoxSingle,
    Btn,
    Nullable,
    BadgeActive,
    StatsMessages,
    StatsClicks,
    StatsRevenues
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      actions: FeedbackActions,
      events: FeedbackEvents,
      feedback: feedback,
      feedbackReqDone: false,
      feedbackStats: {}
    }
  },
  methods: {
    requestAfter () {
      this.feedbackReqDone = true

      if (!this.feedback.id) return

      this.loadFeedbackStats()
    },
    loadFeedbackStats () {
      this.$http
        .get('/feedbacks/stats', { params: { ids: [this.id] } })
        .then((res) => {
          const { data: { data } } = res
          const id = Number(this.id)

          this.feedbackStats = data.find((stats) => stats.id === id)
        })
    }
  }
}
</script>
